document.querySelectorAll("form").forEach((form) => {
    form.addEventListener("submit", function () {
        let inputs = form.getElementsByTagName("input");
        let selects = form.getElementsByTagName("select");

        for (var i = 0; i < inputs.length; i++) {
            removeEmpty(inputs[i]);
        }

        for (var i = 0; i < selects.length; i++) {
            removeEmpty(selects[i]);
        }
    });
});

function removeEmpty(item) {
    if (item.name && !item.value) {
        item.name = "";
    }
}
