let scriptEle = document.createElement("script");

scriptEle.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=G-F53PB9KR7K");
document.body.appendChild(scriptEle);

window.dataLayer = window.dataLayer || [];

function gtag() {
    dataLayer.push(arguments);
}

gtag('js', new Date());
gtag('config', 'G-F53PB9KR7K');
