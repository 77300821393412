import Splide from "@splidejs/splide";

window.registerSplide = function registerSplide(
    splide = null,
    thumbnail = null,
) {
    let thumbnailSplide = null;
    let primarySplide = null;

    if (thumbnail != null) {
        try {
            thumbnailSplide = new Splide(thumbnail, {
                fixedWidth: 100,
                height: 60,
                gap: 10,
                cover: true,
                isNavigation: true,
                pagination: false,
                arrows: false,
                focus: "center",
            });
        } catch (ignored) {}
    }

    if (splide != null) {
        try {
            primarySplide = new Splide(splide, {
                drag: true,
                pagination: false,
                arrows: false,
                heightRatio: 0.5625,
                lazyLoad: true,
                slideFocus: true,
            });
        } catch (ignored) {}
    }

    if (thumbnailSplide != null) {
        thumbnailSplide.mount();

        if (primarySplide != null) {
            primarySplide.sync(thumbnailSplide);
        }
    }

    if (primarySplide != null) {
        primarySplide.mount();
    }
};
