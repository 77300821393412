import RevolutCheckout from "@revolut/checkout"

window.RevolutCheckout = RevolutCheckout;

window.createCardField = function (token, trigger, target) {
    if (token != null && trigger != null && target != null) {
        RevolutCheckout(token, "prod").then(function (instance) {
            const cardField = instance.createCardField({
                target: target,

                /*
                onSuccess() {
                    window.alert("Thank you!")
                },

                onError(error) {
                    window.alert(`Something went wrong. ${error}`)
                }
                 */
            })

            trigger.addEventListener("click", function () {
                var meta = {
                    name: "Niall Flynn",
                    email: "tcr@randomain.ch",
                    phone: "+353871921561",
                    cardholderName: "Niall Flynn"
                }

                cardField.submit(meta);
            });
        });
    }
}

window.payWithPopup = function (token, trigger) {
    if (token != null && trigger != null) {
        RevolutCheckout(token, "prod").then(function (instance) {
            trigger.addEventListener("click", function () {
                instance.payWithPopup({
                    /*
                    onSuccess() {
                        window.alert("Thank you!")
                    },

                    onError(error) {
                        window.alert(`Something went wrong. ${error}`)
                    }
                     */
                });
            });
        });
    }
}
