window.switchLanguage = function (language = 'en') {
    try {
        Weglot.switchTo(language);
    } catch (ignored) {
        alert('Currently unable to switch language.');
    }
}

document.addEventListener('livewire:navigated', () => {
    loadScript('https://cdn.weglot.com/weglot.min.js').then(() => {
        Weglot.initialize({
            api_key: 'wg_016c3a78ee69da8eb6983f11e7ccd53c4',
            auto_switch: true,
            auto_switch_fallback: 'en',
            excluded_blocks: [
                {
                    value: '.no-translate'
                }
            ],
            hide_switcher: true,
            cache: true,
            wait_transition: false,
            remove_unused_link_hooks: false,
        });
    });
});
